import { Component } from "react"
import { Container } from '@material-ui/core';
import LoSentimosCard from '../../common/LoSentimosCard'
import { withThemeProps } from "@findep/microfronts-core"
import PropTypes from 'prop-types'

class AfiLoSentimosLow extends Component {
    constructor(props) {
        super(props)
    }

    // function LoSentimosAfi({ companyName }) {

    render() {
        const {
            companyName,
            dataInfo
        } = this.props
        return (
            <Container justify="center">
                <LoSentimosCard data={dataInfo} companyName={companyName} />
            </Container>

        )
    }
}

export default withThemeProps(AfiLoSentimosLow, 'VDPNAfiLoSentimosLow')
AfiLoSentimosLow.propTypes = {
    companyName: PropTypes.string,
    dataInfo: PropTypes.object
}
AfiLoSentimosLow.defaultProps = {
    companyName: "Afi",
    dataInfo: {
        "textHeader": "Muchas gracias por tu interés en obtener un préstamo con nosotros.",
        "textBox": "En este momento no cumples con los requisitos mínimos requeridos para continuar con tu solicitud.",
        "textBoxTwo": "Esperamos poder apoyarte en el futuro.",
        "textBoxThree": ""
    }
}
// export default LoSentimosAfi

